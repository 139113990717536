import React, { useState } from 'react';
import {
    Button, Checkbox, Input,
    //  Form, Input, 
    Modal
} from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { RightOutlined } from "@ant-design/icons";
import useReduxCatalog from '../../../hoc/hooks/useCatalog';

interface PopupModalProps {
    visible: boolean;
}


type DataStructure = {
    [category: string]: {
        subcategories: {
            [subcategory: string]: string[];
        };
    };
};




const ModalCustomScor: React.FC<PopupModalProps> = ({visible}) => {
    const {
        setShowModalShowCustomSCroring,
        setsetDataCustomScoring,
        getListDataProduct,
        dataListProduct
    } = useReduxCatalog();
// console.log(dataListProduct);

    const [checkedState, setCheckedState] = useState<Record<string, boolean>>({});
    const [, setSelectedItems] = useState<DataStructure>({});
    const dummyData: DataStructure = dataListProduct || {};
    // console.log(selectedItems);
    
    // const [searchQuery, setSearchQuery] = useState<string>("");

    // Initialize default checks for categories and their subcategories/items
    const initializeChecks = () => {
        const initialState: Record<string, boolean> = {};

        Object.entries(dummyData).forEach(([category, { subcategories }]) => {
            // Mark all categories as checked by default
            initialState[category] = true;

            // Mark all subcategories and items under the category as checked
            Object.entries(subcategories).forEach(([subcategory, items]) => {
                initialState[subcategory] = true;
                items.forEach((item) => {
                    initialState[item] = true;
                });
            });
        });

        setCheckedState(initialState);
    };

    // Handle checks for categories, subcategories, and items
    const handleCheck = (key: string, checked: boolean) => {
        setCheckedState((prev) => {
            const updatedState = { ...prev, [key]: checked };

            // If the key is a category, toggle all its subcategories and items
            if (dummyData[key]) {
                const subcategories = dummyData[key].subcategories;
                Object.keys(subcategories).forEach((subcategory) => {
                    updatedState[subcategory] = checked;
                    subcategories[subcategory].forEach((item) => {
                        updatedState[item] = checked;
                    });
                });
            }

            // If the key is a subcategory, toggle its items
            Object.entries(dummyData).forEach(([category, { subcategories }]) => {
                if (subcategories[key]) {
                    subcategories[key].forEach((item) => {
                        updatedState[item] = checked;
                    });
                }
            });

            return updatedState;
        });
    };

    const handleChoose = () => {
        const selected: DataStructure = {};
    
        Object.entries(dummyData).forEach(([category, { subcategories }]) => {
            if (checkedState[category]) {
                selected[category] = { subcategories: {} };
    
                Object.entries(subcategories).forEach(([subcategory, items]) => {
                    if (checkedState[subcategory]) {
                        selected[category].subcategories[subcategory] = [];
    
                        items.forEach((item) => {
                            if (checkedState[item]) {
                                selected[category].subcategories[subcategory].push(item);
                            }
                        });
    
                        // Hapus subkategori jika tidak ada item yang dipilih
                        if (selected[category].subcategories[subcategory].length === 0) {
                            delete selected[category].subcategories[subcategory];
                        }
                    }
                });
    
                // Hapus kategori jika tidak ada subkategori yang dipilih
                if (Object.keys(selected[category].subcategories).length === 0) {
                    delete selected[category];
                }
            }
        });
    
        setSelectedItems(selected);
        setsetDataCustomScoring(selected)
        setShowModalShowCustomSCroring(false);
    };
    
    // Initialize default checks when modal is opened
    React.useEffect(() => {
        if (visible) {
            // initializeChecks();
            getListDataProduct()
        }
        // eslint-disable-next-line
    }, [visible]);

    React.useEffect(() => {
        if (dataListProduct !== null) {
            initializeChecks();
            // getListDataProduct()
        }
        // eslint-disable-next-line
    }, [dataListProduct]);

    return (
        <div>
            {/* <Button type="primary" onClick={() => setIsModalOpen(true)}>
                Open Modal
            </Button> */}
            <Modal
             styles={{
                content: {
                    backgroundColor: '#333939'
                },
                body: {
                    backgroundColor: '#333939',
                    color: 'white',
                },
            }}
              closeIcon={<CloseOutlined style={{ color: 'white' }} />} // Set the close icon color to white
               title={
                <div
                    style={{
                            backgroundColor: '#333939',
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingRight: '20px',
                            width: "100%", // Ensure it doesn't exceed modal width
                        }}
                        >
                        <span style={{ flexShrink: 0, color:'white' }}>Data Source</span>
                        <Input
                            placeholder="Search..."
                            allowClear
                            style={{ width: "300px", maxWidth: "100%" }} // Ensure it fits within the modal
                        />
              </div>
              }
                open={visible}
                onCancel={() => setShowModalShowCustomSCroring(false)}
                footer={[
                    <Button key="choose" type="primary" onClick={handleChoose}>
                    Choose
                </Button>,
                ]}
                width={800}
            >


                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    height: "400px",
                    marginTop:'15px',
                    marginBottom:'15px',
                    border:"1px solid #e8e8e8",
                    borderRadius:'3px'
                }}>
                    {/* Categories */}
                    <div
                        style={{
                            flex: 1,
                            backgroundColor: '#333939',
                            borderRadius: "4px",
                            overflowY: "auto",
                            padding: "10px",
                            borderRight: "1px solid #e8e8e8",
                            scrollbarWidth: 'none', // Firefox
                            msOverflowStyle: 'none', // Internet Explorer
                           
                        }}
                    >
                        {Object.keys(dummyData).map((category) => (
                            <div
                                key={category}
                                style={{
                                    padding: "10px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between", // Biar ikon ada di kanan
                                }}
                            >
                                <Checkbox
                                    checked={checkedState[category] || false}
                                    onChange={(e) => handleCheck(category, e.target.checked)}
                                >
                                   <p style={{color:'white'}}>{category}</p> 
                                </Checkbox>
                                <RightOutlined style={{ color: "white", fontSize: "14px" }} />
                            </div>
                        ))}
                    </div>

                    {/* Subcategories */}
                    <div
                        style={{
                            flex: 1,
                            backgroundColor: '#333939',
                            borderRadius: "4px",
                            overflowY: "auto",
                            borderRight: "1px solid #e8e8e8",
                            padding: "10px",
                            scrollbarWidth: 'none', // Firefox
                            msOverflowStyle: 'none', // Internet Explorer
                        }}
                    >
                        {Object.keys(dummyData).map(
                            (category) =>
                                checkedState[category] && (
                                    <div key={category}>
                                        {Object.keys(dummyData[category].subcategories).map((subcategory) => (
                                            <div
                                                key={subcategory}
                                                style={{
                                                    padding: "10px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "space-between", // Biar ikon ada di kanan
                                                }}
                                            >
                                                <Checkbox
                                                    checked={checkedState[subcategory] || false}
                                                    onChange={(e) => handleCheck(subcategory, e.target.checked)}
                                                >
                                              <p style={{color:'white'}}>{subcategory}</p> 
                                                </Checkbox>
                                                <RightOutlined style={{ color: "white", fontSize: "14px" }} />
                                            </div>
                                        ))}
                                    </div>
                                )
                        )}
                    </div>

                    {/* Items */}
                    <div
                        style={{
                            flex: 1,
                            backgroundColor: '#333939',
                            borderRadius: "4px",
                            overflowY: "auto",
                            padding: "10px",
                            // borderRight: "1px solid #e8e8e8",
                            scrollbarWidth: 'none', // Firefox
                            msOverflowStyle: 'none', // Internet Explorer
                        }}
                    >
                        {Object.keys(dummyData).map((category) =>
                            Object.keys(dummyData[category].subcategories).map(
                                (subcategory) =>
                                    checkedState[subcategory] && (
                                        <div key={subcategory}>
                                            {dummyData[category].subcategories[subcategory].map((item) => (                                                
                                                <div
                                                    key={item}
                                                    style={{
                                                        padding: "10px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "space-between", // Biar ikon ada di kanan
                                                    }}
                                                >
                                                    <Checkbox
                                                        checked={checkedState[item] || false}
                                                        onChange={(e) => handleCheck(item, e.target.checked)}
                                                    >
                                                       <p style={{color:'white'}}>{item}</p> 
                                                    </Checkbox>
                                                    <RightOutlined style={{ color: "white", fontSize: "14px" }} />
                                                </div>
                                            ))}
                                        </div>
                                    )
                            )
                        )}
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default ModalCustomScor;
