import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Space } from 'antd';
// import { ToogleMenu } from '../../shared/drawtools/toogle.menu';
import icAreaSize from '../../asesst/Icons/interactivemap/icon/scale-card.svg';
import icGridTotal from '../../asesst/Icons/interactivemap/icon/grid-card.svg';
import icButton from '../../asesst/new/icon/Bullet.svg'
import useReduxCatalog from '../../hoc/hooks/useCatalog';
import { Input } from 'antd';
import ModalCustomScor from '../../shared/map/modal/formModalCustomScoring';
// import { ModalDefault } from '../../shared/map/modal/modal-checkout';

export const MenuScoring: React.FC<any> = () => {
    const [percentages, setPercentages] = useState<Record<string, number>>({});
    const [errors, setErrors] = useState<Record<string, boolean>>({});
    const [isChecked, setIsChecked] = useState(false); // State untuk checklist

    const {
        setMetaData,
        setDataLayerGroup,
        setShowModalShowCustomSCroring,
        setShowSideMenuCustomScoring,
        dataCustomScoring,
        showModalCustomScoring
    } = useReduxCatalog();
    // console.log(dataCustomScoring);
    const data = dataCustomScoring
    useEffect(() => {
        setMetaData(''); // Reset the geometry to null
        setDataLayerGroup([])
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (data !== null) {
            // Inisialisasi persentase dengan nilai yang dibagi rata
            const allItems = Object.values(data).flatMap((category) =>
                Object.values(category.subcategories).flat()
            );
            const initialPercentage = Math.floor(100 / allItems.length); // Dibulatkan ke bawah
            const initialPercentages = allItems.reduce((acc, item) => {
                acc[item] = initialPercentage; // Hanya angka bulat
                return acc;
            }, {} as Record<string, number>);
            setPercentages(initialPercentages);
        }
    }, [data]); // Tambahkan `data` sebagai dependency

    const handlePercentageChange = (item: string, newValue: number) => {
        if (isChecked) return; // Jangan ubah jika checklist aktif
        const clampedValue = Math.max(0, Math.min(newValue, 100)); // Batasi nilai antara 0 dan 100

        const totalPercentage =
            Object.values(percentages).reduce((sum, value) => sum + value, 0) -
            percentages[item] +
            clampedValue;

        // Validasi total tidak lebih dari 100
        if (totalPercentage > 100) {
            setErrors((prev) => ({ ...prev, [item]: true }));
            return;
        } else {
            setErrors((prev) => ({ ...prev, [item]: false }));
        }

        setPercentages((prev) => ({
            ...prev,
            [item]: clampedValue,
        }));
    };
    let allItems: {
        category: any;
        subcategory: string;
        items: string[];
    }[] = [];

    if (data !== null) {
        allItems = Object.values(data).flatMap((category) =>
            Object.entries(category.subcategories).map(([subcategory, items]) => ({
                category,
                subcategory,
                items,
            }))
        );
    }

    const handleCheck = (checked: boolean) => {
        setIsChecked(checked);

        if (checked && data !== null) {
            // Reset persentase menjadi dibagi rata
            const allItems = Object.values(data).flatMap((category) =>
                Object.values(category.subcategories).flat()
            );
            const equalPercentage = Math.floor(100 / allItems.length);
            const resetPercentages = allItems.reduce((acc, item) => {
                acc[item] = equalPercentage;
                return acc;
            }, {} as Record<string, number>);
            setPercentages(resetPercentages);
        }
    };
    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        console.log(e);
    };

    return (
        <React.Fragment>
            <Space
                direction="vertical"
                style={{
                    position: 'absolute',
                    left: '320px',
                    top: '0%',
                    zIndex: 100,
                    transition: '0.25s ease-in-out',
                }}
            >
                <div
                    style={{
                        background: '#333939',
                        border: '1px #d9d9d9',
                        color: 'white',
                        borderRadius: '12px',
                        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
                        width: '300px',
                        padding: '15px',
                        height: 'calc(100vh - 50px)',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                    }}
                >

                    <div
                        style={{
                            backgroundColor: '#333939',
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingRight: '20px',
                            width: "100%", // Ensure it doesn't exceed modal width
                        }}
                    >
                        <h2 style={{ color: 'white' }}>Custom Scoring</h2>

                        <Button
                            onClick={() => setShowSideMenuCustomScoring(false)}
                            style={{
                                backgroundColor: 'white', // Ubah warna tombol menjadi putih
                                color: 'black', // Ubah warna teks menjadi hitam
                                height: "35px",
                                width: "35px",
                                display: 'flex',
                                position: 'absolute',
                                right: '-15px',
                                borderRadius: '50%',
                                justifyContent: 'center', // Mengatur ikon ke tengah secara horizontal
                                alignItems: 'center', // Mengatur ikon ke tengah secara vertikal
                            }}
                        >
                            X
                        </Button>
                    </div>
                    {/* enable ketika data sudah di selec */}
                    {dataCustomScoring !== null &&
                        <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '10px', maxWidth: '432px', marginBottom:'-15px' }}>
                            <div
                                style={{
                                    // border: '1px solid white',
                                    background: '#141617',
                                    paddingLeft: '10px',
                                    paddingTop: '10px',
                                    fontSize: '10px',
                                    width: '350px',
                                    height: '60px',
                                    borderTopLeftRadius: '8px',
                                    borderBottomLeftRadius: '8px',
                                    marginBottom: '10px',
                                }}
                            >
                                <img
                                    src={icAreaSize}
                                    alt="Button Icon"
                                    style={{ marginRight: '10px' }} />
                                <span style={{ color: '#DCE1E7', fontWeight: 'bold', fontSize: '12px' }}>Size of the area</span>
                                <p style={{ color: '#DCE1E7', fontSize: '12px', marginTop: '5px' }}>
                                    200 km²
                                </p>
                            </div>
                            <div
                                style={{
                                    fontSize: '10px',
                                    paddingLeft: '10px',
                                    paddingTop: '10px',
                                    height: '60px',
                                    alignItems: 'center',
                                    width: '350px',
                                    borderTopRightRadius: '8px',
                                    borderBottomRightRadius: '8px',
                                    marginBottom: '20px',
                                    // border: '1px solid white',
                                    background: '#141617',
                                }}
                            >
                                <img
                                    src={icGridTotal}
                                    alt="Button Icon"
                                    style={{ marginRight: '10px' }} />
                                <span style={{
                                    fontWeight: 'bold',
                                    fontSize: '12px',
                                    color: '#DCE1E7',

                                }}>Square</span>
                                <p style={{ color: '#DCE1E7', fontSize: '12px', marginTop: '5px' }}>
                                    2 m2
                                </p>
                            </div>
                        </div>
                    }
                    {/* enable ketika data sudah di selec */}
                    <p style={{ fontSize: '12px', color: 'rgba(255, 255, 255, 0.65)', marginTop: '5px', marginBottom: '5px' }}>
                        Insight Name
                    </p>
                    <Input placeholder="E.g., Scoring CITOS" allowClear onChange={onChange} />
                    <h4 style={{ color: 'white', marginTop: '10px' }}>Choose Source Data</h4>
                    <p style={{ fontSize: '12px', color: 'rgba(255, 255, 255, 0.65)', marginTop: '5px', marginBottom: '10px' }}>
                        Select which data you need for scoring.
                    </p>
                    {dataCustomScoring !== null &&
                        <><p style={{ fontSize: '12px', color: 'rgba(255, 255, 255, 0.65)', marginBottom: '10px' }}>
                            Adjust percentages for each item. Total must not exceed 100%.
                        </p><div
                            style={{
                                backgroundColor: '#141617',
                                padding: '10px',
                                borderRadius: '10px',
                                minHeight: '250px',
                                maxHeight: '300px',
                                overflowY: 'auto', // Scroll secara vertikal jika konten melebihi maxHeight
                                scrollbarWidth: 'none', // Firefox
                                msOverflowStyle: 'none', // Internet Explorer
                            }}
                        >
                                {allItems.map(({ subcategory, items }) => (
                                    <div key={subcategory} style={{ marginBottom: '10px' }}>
                                        {items.map((item) => (
                                            <div
                                                key={item}
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    marginBottom: '8px',
                                                }}
                                            >
                                                <div>
                                                    <h4 style={{ color: 'white', fontSize: '14px' }}>{item}</h4>
                                                    <span style={{ color: 'white', fontSize: '10px' }}>{subcategory}</span>
                                                </div>
                                                <Input
                                                    type="text" // Hilangkan spinner
                                                    value={`${percentages[item] || 0}%`} // Tambahkan simbol %
                                                    onChange={(e) => handlePercentageChange(
                                                        item,
                                                        parseInt(e.target.value.replace(/[^0-9]/g, ''), 10) || 0
                                                    )}
                                                    disabled={isChecked}
                                                    style={{
                                                        width: '60px',
                                                        borderColor: errors[item] ? 'red' : undefined,
                                                        color: isChecked ? 'gray' : 'black', // Teks abu-abu saat disabled
                                                        backgroundColor: isChecked ? '#f5f5f5' : undefined, // Tambahkan latar belakang abu-abu untuk kesan disabled
                                                    }} />
                                                {/* {errors[item] && (
                                                    <p style={{ color: 'red', fontSize: '12px' }}>
                                                        Total exceeds 100%
                                                    </p>
                                                )} */}
                                            </div>
                                        ))}
                                    </div>
                                ))}


                            </div>
                            <div style={{
                                padding: '10px',
                            }}>
                                <Checkbox
                                    checked={isChecked}
                                    onChange={(e: { target: { checked: boolean; }; }) => handleCheck(e.target.checked)}
                                    style={{ marginBottom: '15px', color: 'white' }}
                                >
                                    <p style={{
                                        fontSize: '12px'
                                    }}>Divide all data percentages equally</p>
                                </Checkbox>
                            </div>

                        </>
                    }


                    <Button
                        type="primary"
                        icon={<img src={icButton} alt="chose data" />}
                        style={{
                            height: '35px',
                            borderRadius: '8px',
                            backgroundColor: '#4A7FC0',
                            display: 'flex',
                            // marginTop: '20px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: '14px',
                            fontWeight: 700,
                        }}
                        onClick={() => setShowModalShowCustomSCroring(true)} // Tutup drawer
                    >
                        {dataCustomScoring !== null ? 'Add Another Data' : ' Choose Data'}

                    </Button>

                    <div style={
                        {
                            width: '100%',
                            position: 'absolute',
                            marginBottom: '10px',
                            bottom: '0px',
                        }
                    }>
                        <Button
                            type="primary"
                            style={{
                                height: '35px',
                                width: '270px',
                                borderRadius: '8px',
                                backgroundColor: '#4A7FC0',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: '14px',
                                fontWeight: 700,
                            }}
                        // onClick={handlePointButtonClick}
                        >
                            Buy Scoring
                        </Button>
                    </div>
                    <ModalCustomScor visible={showModalCustomScoring} />
                    {/* <ModalDefault 
                        isModal={true}
                        data={selectedItem}
                    /> */}
                    {/* <ToogleMenu onClose={onClose}/> */}
                </div>

            </Space>
        </React.Fragment>
    );
};
