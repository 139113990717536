import React, { useState } from 'react';
import { Drawer, Button, Space, Tooltip } from 'antd';
import { CloseOutlined } from '@ant-design/icons'; // Import the close icon
import CatalogMenuMobile from './drawer.menu.catalog.mobile';
import useReduxCatalog from '../../../../hoc/hooks/useCatalog';
import { MyDataMobile } from './drawer.mydata.mobile';
import iconHeader from '../../../../asesst/Icons/interactivemap/icon.svg';
import { MenuCartCatalogMobile } from '../../../../component/menu/menu.mobile/menu.cart.mobile';
import { DrawerChatAiMobile } from './drawer.chatAI.mobile';
interface MenuItem {
    icon: string; // Path or URL to the icon image
    label: string; // Label for the menu item
}

interface DrawerSideMenuProps {
    menuItems: MenuItem[];
    map: maplibregl.Map | null; // Pass the map instance as a prop
}

const BottomDrawerMenu: React.FC<DrawerSideMenuProps> = ({ menuItems, map }) => {
    // const [visible, setVisible] = useState(false);
    const [selectedMenu, setSelectedMenu] = useState<string | null>(null);
    const {
        setShowCardCatalog,
        setShowCardMyData,
        setDataLayerGroup,
        setShowDrawerBottom,
        setShowSideMenuCustomScoring,
        showDrawerBottom,
    } = useReduxCatalog();

    const showDrawer = () => {
        setShowDrawerBottom(true);
    };

    const onClose = () => {
        setShowDrawerBottom(false);
        setSelectedMenu(null); // Reset to main menu when closing
        setShowCardCatalog(false)
        setShowSideMenuCustomScoring(false);
        setShowCardMyData(false);
        setDataLayerGroup([])
    };

    const handleMenuClick = (menuLabel: string) => {
        setSelectedMenu(menuLabel);
    };

    const handleBack = () => {
        setSelectedMenu(null); // Go back to the main menu
        setShowCardCatalog(false)
        setShowSideMenuCustomScoring(false);
        setShowCardMyData(false);
        setDataLayerGroup([])
    };

    const renderSubMenu = () => {
        switch (selectedMenu) {
            case 'Catalogue':
                return <CatalogMenuMobile onBack={handleBack} />;
            case 'My Data': // Replace with actual label of the menu item
                return <MyDataMobile onBack={handleBack} map={map} />;
            case 'Cart': // Replace with actual label of the menu item
                return <MenuCartCatalogMobile onBack={handleBack} />;
            case 'Chat wiht AI': // Replace with actual label of the menu item
                return <DrawerChatAiMobile onBack={handleBack} />;
            // Add more cases for other submenus as needed
            default:
                return null;
        }
    };

    return (
        <div>
            {/* Button to open the drawer */}
            <Space
                direction='vertical'
                style={{
                    position: 'absolute',
                    right: '15px',
                    top: 80,
                    zIndex: 100,
                    transition: '0.25s ease-in-out',
                }}
            >
                <Tooltip
                    title={<p style={{ color: 'black' }}>Menu Catalog</p>}
                    placement="top"
                    color='white'
                >
                    <Button
                        type="primary"
                        onClick={showDrawer}
                        style={{
                            backgroundColor: '#1E334D',
                            height: "50px",
                            width: "50px",
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: '12px'
                        }}
                    >
                        {/* Menu Catalogue */}
                        <img
                            src={iconHeader}
                            style={{
                                width: '40px',
                                height: '40px'
                            }}
                            alt="Layer Icon"
                        />
                    </Button>
                </Tooltip>
            </Space>
            {/* Bottom drawer */}
            <Drawer
                placement="bottom"
                onClose={onClose}
                open={showDrawerBottom}
                height={400}
                mask={false}
                closable={false}
                drawerStyle={{
                    backgroundColor: '#1E334D',
                    borderTopLeftRadius: '20px',
                    borderTopRightRadius: '20px',
                    touchAction: 'pan-y',
                    zIndex: 100,
                    position: 'relative', // Make sure we can position the close button inside
                }}
            >
                {/* Custom close button */}

                <Button
                    type="text"
                    icon={<CloseOutlined style={{ color: 'white', fontSize: '18px' }} />}
                    onClick={onClose}
                    style={{
                        position: 'absolute',
                        top: '10px',
                        right: '20px',
                        zIndex: 1001,
                        background: 'transparent',
                        border: 'none',
                    }}
                />

                {/* Render the submenu if a menu is selected */}
                {selectedMenu ? (
                    renderSubMenu()
                ) : (
                    <>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',  // Horizontally centers the icon and text
                                justifyContent: 'center', // Vertically centers the content
                                marginBottom: '20px',
                                cursor: 'pointer'
                            }}>

                            <img
                                src={iconHeader}
                                alt='header'
                                style={{
                                    width: '50px',
                                    height: '50px',
                                    marginBottom: '8px',
                                }}
                            />

                            <span
                                style={{
                                    fontSize: '18px',
                                    fontWeight: 'bold',
                                    color: 'white',
                                    textAlign: 'center' // Ensures the text is centered under the icon
                                }}
                            >
                                Menu
                            </span>
                        </div>

                        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
                            {menuItems.map((item, index) => (
                                <div
                                    key={index}
                                    style={{
                                        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
                                        width: '45%',
                                        height: '80px',
                                        borderRadius: '12px',
                                        marginBottom: '20px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => handleMenuClick(item.label)} // Handle menu item click
                                >
                                    {/* Render the icon */}
                                    <img
                                        src={item.icon}
                                        alt={item.label}
                                        style={{
                                            width: '50px',
                                            height: '50px',
                                            marginBottom: '8px',
                                        }} />
                                    {/* Render the label */}
                                    <span style={{ textAlign: 'center', color: 'white' }}>{item.label}</span>
                                </div>
                            ))}
                        </div></>
                )}
            </Drawer>
        </div>
    );
};

export default BottomDrawerMenu;
