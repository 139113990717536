import React from 'react';
import { Space } from 'antd';
import { ToogleMenu } from '../../shared/drawtools/toogle.menu';
import { MyData } from '../../shared/map/drawer/drawer.mydata';
import useReduxCatalog from '../../hoc/hooks/useCatalog';
import { DrawerSeeData } from '../../shared/map/drawer/drawer.info.seedata';
interface MenuCatalogProps {
    onClose: () => void;
    map: maplibregl.Map | null;
}
export const MenuMyData:  React.FC<MenuCatalogProps> = ({ onClose,map }) => {
    const {
        metaData,
        setMetaData,
        setDataLayerGroup
    } = useReduxCatalog();

    const handleBackClick = () => {
        setMetaData(''); // Reset the geometry to null
        setDataLayerGroup([])
    };
    return (
        <React.Fragment>
            <Space
                direction="vertical"
                style={{
                    position: 'absolute',
                    left: '120px',
                    top: '3%',
                    zIndex: 100,
                    transition: '0.25s ease-in-out',
                }}
            >
                <div>
                    {!metaData ? (
                        <div
                        style={{
                            background: '#333939',
                            border: '1px #d9d9d9',
                            color: 'white',
                            borderRadius: '12px',
                            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
                            width: '300px',
                            padding: '15px',
                            height: 'calc(100vh - 50px)',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                        }}
                        >
                        <MyData />
                        <ToogleMenu onClose={onClose}/>
                        </div>
                    ) : (
                        <div
                        style={{
                            background: '#333939',
                            border: '1px #d9d9d9',
                            color: 'white',
                            borderRadius: '12px',
                            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
                            width: '350px',
                            padding: '15px',
                            height: 'calc(100vh - 50px)',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                        }}
                        >
                       <DrawerSeeData onBackClick={handleBackClick} map={map} />
                       </div>
                    )}

          
                </div>

            </Space>
        </React.Fragment>
    );
};
