import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/config.strore';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
    getCatalogAction,
    getCatalogGeometryAction,
    getCatalogLocationAction,
    drawerActionOpen,
    openModalCheckout,
    closeModalCheckout,
    addChart,
    setSelectedItemAction,
    setListCartAction,
    openModalCartAction,
    closeModalCartAction,
    setDataCartAction,
    setMyDataAction,
    setDetailMyDataAction,
    setMetaDataAction,
    setDataLayerGroupAction,
    setColorGroupAction,
    setInvoiceIdAction,
    openModalConfirmPaymentAction,
    closeModalConfirmPaymentAction,
    setSelectedCategoryAction,
    setShowCardCatalogAction,
    setShowCardMyDataAction,
    setShowDrawerBottomAction,
    setPopUpFilterAction,
    setModalWalletPaymentAction,
    setIsDrawActiveAction,
    setDrawnPolygonAction,
    setIsochronesAction,
    setInvoiceForDataAction,
    setDownloadDataAction,
    setModalWalletCartPaymentAction,
    setShowCardDownloadAction,
    setShowRightDrawerAction,
    setGenerateDescriptionAction,
    setShowModalShowCustomSCroringAction,
    setShowSideMenuCustomScoringAction,
    setsetDataCustomScoringAction,
    getListDataProductAction
} from '../action/catalog.action';
import { FeatureCollection, Geometry } from 'geojson'; 
interface DataStructure  {
    [category: string]: {
        subcategories: {
            [subcategory: string]: string[];
        };
    };
};
const useReduxCatalog = () => {
    const dispatch = useDispatch<ThunkDispatch<RootState, null, AnyAction>>(); // Use ThunkDispatch
    const {
        catalog,
        paramsCatalogGeometry,
        location,
        isloading,
        drawActive,
        isModalCheckout,
        addToChart,
        selectedItem,
        listCart,
        isModalCart,
        setCartCheckout,
        myData,
        detailMydata,
        metaData,
        dataLayerGroup,
        colorGroup,
        invoice_id,
        isModalConfirmPayment,
        selectedCategory,
        showCardCatalog,
        showCardMyData,
        showDrawerBottom,
        popUpFilter,
        modalWalletPayment,
        isDrawActive,
        drawnPolygon,
        isochrones,
        invoiceForData,
        downloadData,
        modalWalletCartPayment,
        showCardDownload,
        showRightDrawer,
        showModalCustomScoring,
        showSideMenuCustomScoring,
        dataCustomScoring,
        dataListProduct
        
    } = useSelector((state: RootState) => state.catalog);

    const setCatalog = async (params: string, geom: any) => {
        dispatch(getCatalogAction(params, geom));
    };
    const setCatalogGeometry = async (geom: any) => {
        dispatch(getCatalogGeometryAction(geom));
    };
    const setCatalogLocation = async (data: any) => {
        dispatch(getCatalogLocationAction(data));
    };
    const setDrawActive = async (isActive: any) => {
        dispatch(drawerActionOpen(isActive));
    };
    const setModalOpenCheckout = async (status: any) => {
        dispatch(openModalCheckout(status));
    };
    const setModalCloseCheckout = async (status: any) => {
        dispatch(closeModalCheckout(status));
    };
    const setChart = async (token: string, data: any) => {
        return await dispatch(addChart(token, data)); // Ensure the action is awaited and 
    };
    const setSelectedItem = async (data: any) => {
        return await dispatch(setSelectedItemAction(data)); // Ensure the action is awaited and 
    };
    const setListCart = async () => {
        return await dispatch(setListCartAction()); // Ensure the action is awaited and 
    };
    const setModalOpenCart = async (status: any) => {
        dispatch(openModalCartAction(status));
    };
    const setModalCloseCart = async (status: any) => {
        dispatch(closeModalCartAction(status));
    };
    const setDataCart = async (data: any) => {
        dispatch(setDataCartAction(data));
    };
    const setMyData = async (params: string) => {
        dispatch(setMyDataAction(params));
    };
    const setDetailMyData = async (params: string) => {
        dispatch(setDetailMyDataAction(params));
    };
    const setMetaData = async (data: string) => {
        dispatch(setMetaDataAction(data));
    };
    const setDataLayerGroup = async (data: any) => {
        dispatch(setDataLayerGroupAction(data));
    };
    const setColorGroup = async (colorGroup: any) => {
        dispatch(setColorGroupAction(colorGroup));
    };
    const setInvoiceId = async (data: any) => {
        dispatch(setInvoiceIdAction(data));
    };
    const setOpenModalConfirmPayment = async (status: any) => {
        dispatch(openModalConfirmPaymentAction(status));
    };
    const setCloseModalConfirmPayment = async (status: any) => {
        dispatch(closeModalConfirmPaymentAction(status));
    };
    const setSelectedCategory = async (data: any) => {
        dispatch(setSelectedCategoryAction(data));
    };
    const setShowCardCatalog = async (status: any) => {
        dispatch(setShowCardCatalogAction(status));
    };
    const setShowCardMyData = async (status: any) => {
        dispatch(setShowCardMyDataAction(status));
    };
    const setShowDrawerBottom = async (status: any) => {
        dispatch(setShowDrawerBottomAction(status));
    };
    const setPopUpFilter = async (data: any) => {
        dispatch(setPopUpFilterAction(data));
    };
    const setModalWalletPayment = async (status: any) => {
        dispatch(setModalWalletPaymentAction(status));
    };
    const setIsDrawActive = async (status: any) => {
        dispatch(setIsDrawActiveAction(status));
    };
    const setDrawnPolygon = async (data: any) => {
        dispatch(setDrawnPolygonAction(data));
    };
    // const setIsochronest = async (body: any) => {
    //     return dispatch(setIsochronesAction(body));
    // };
    const setIsochronest = async (body: any): Promise<FeatureCollection<Geometry>> => {
        return dispatch(setIsochronesAction(body)) as unknown as Promise<FeatureCollection<Geometry>>;
    };
    const setInvoiceForData = async (param: any) => {
        return dispatch(setInvoiceForDataAction(param));
    };
    const setDownloadData = async (param: any) => {
        return dispatch(setDownloadDataAction(param));
    };

    const setModalWalletCartPayment = async (status: any) => {
        dispatch(setModalWalletCartPaymentAction(status));
    };
    const setShowCardDownload = async (status: any) => {
        dispatch(setShowCardDownloadAction(status));
    };
    const setShowRightDrawer = async (status: any) => {
        dispatch(setShowRightDrawerAction(status));
    };
    const setGenerateDescription = async (body: any) => {
        return await dispatch(setGenerateDescriptionAction(body)); // Ensure the action is awaited and 
    };
    const setShowModalShowCustomSCroring = async (status: any) => {
        dispatch(setShowModalShowCustomSCroringAction(status));
    };
    const setShowSideMenuCustomScoring = async (status: any) => {
        dispatch(setShowSideMenuCustomScoringAction(status));
    };
    const setsetDataCustomScoring = async (data: DataStructure) => {
        dispatch(setsetDataCustomScoringAction(data));
    };
    const getListDataProduct = async () => {
        dispatch(getListDataProductAction());
    };
    return {
        setCatalog,
        setCatalogGeometry,
        setCatalogLocation,
        setDrawActive,
        setModalOpenCheckout,
        setModalCloseCheckout,
        setChart,
        setSelectedItem,
        setListCart,
        setModalOpenCart,
        setModalCloseCart,
        setDataCart,
        setMyData,
        setDetailMyData,
        setMetaData,
        setDataLayerGroup,
        setColorGroup,
        setInvoiceId,
        setOpenModalConfirmPayment,
        setCloseModalConfirmPayment,
        setSelectedCategory,
        setShowCardCatalog,
        setShowCardMyData,
        setShowDrawerBottom,
        setPopUpFilter,
        setModalWalletPayment,
        setIsDrawActive,
        setDrawnPolygon,
        setIsochronest,
        setInvoiceForData,
        setDownloadData,
        setModalWalletCartPayment,
        setShowCardDownload,
        setShowRightDrawer,
        setGenerateDescription,
        setShowModalShowCustomSCroring,
        setShowSideMenuCustomScoring,
        setsetDataCustomScoring,
        getListDataProduct,
        selectedCategory,
        isModalConfirmPayment,
        catalog,
        location,
        isloading,
        paramsCatalogGeometry,
        drawActive,
        isModalCheckout,
        addToChart,
        selectedItem,
        listCart,
        isModalCart,
        setCartCheckout,
        myData,
        detailMydata,
        metaData,
        dataLayerGroup,
        colorGroup,
        invoice_id,
        showCardCatalog,
        showCardMyData,
        showDrawerBottom,
        popUpFilter,
        modalWalletPayment,
        isDrawActive,
        drawnPolygon,
        isochrones,
        invoiceForData,
        downloadData,
        modalWalletCartPayment,
        showCardDownload,
        showRightDrawer,
        showModalCustomScoring,
        showSideMenuCustomScoring,
        dataCustomScoring,
        dataListProduct
    }; // Return pixelState as well if needed

}

export default useReduxCatalog;